<template>
    <div>
        <window-header></window-header>
        <section>
            <article style="height:265px;width:770px">
                <div class="emision-sidebar">
                    <button @click="filtrar()">
                        <fa-icon icon="filter" size="2x" />
                        Filtrar
                    </button>
                </div>
                <filtro-seleccion-acotacion ref="selaco" style="left: 110px; top: 15px;"
                    referencia="articulos_atributos" model="articulo" :nombre="'Artículos'" />
            </article>
        </section>
    </div>
</template>
<script>
import WindowMixin from "./../components/WindowMixin.vue";
import FiltroSeleccionAcotacion from "./../components/FiltroSeleccionAcotacion.vue";

// TODO: default plantilla

export default {
    mixins: [WindowMixin],
    components: { FiltroSeleccionAcotacion },
    props: {
        parent: Object
    },
    data: function () {
        var self = this;
        return {
            title: "Configuración del editor masivo de atributos",
        };
    },
    mounted: function () {
        //var $ = window.$;
    },
    methods: {
        filtrar(sel = undefined) {
            let self = this;
            self.parent.filtro = self.$refs.selaco.getFilter();
            self.parent.actualizarArticulos(sel);
            self.$emit("close");
        }
    },
};
</script>
<style>
.emision-sidebar {
    display: flex;
    flex-direction: column;
    width: 100px;
}

.emision-sidebar button {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: var(--padding-half);
    padding: var(--padding-half);
}
</style>